// Language List
const languages = [
  'English', 'French', 'Kinyarwanda', 'Arabic', 'Spanish', 'German', 'Italian',
  'Swahili', 'Portuguese', 'Chinese', 'Japanese', 'Russian', 'Hindi', 'Bengali',
  'Urdu', 'Persian', 'Turkish', 'Thai', 'Vietnamese', 'Korean', 'Polish',
  'Dutch', 'Greek', 'Hebrew', 'Czech', 'Hungarian', 'Romanian', 'Serbian',
  'Croatian', 'Malay', 'Filipino', 'Tamil', 'Telugu', 'Punjabi', 'Indonesian',
  'Danish', 'Swedish', 'Norwegian', 'Finnish', 'Icelandic', 'Afrikaans',
  'Amharic', 'Burmese', 'Bulgarian', 'Catalan', 'Cebuano', 'Chichewa', 'Dzongkha',
  'Estonian', 'Fijian', 'Finnish', 'Georgian', 'Gujarati', 'Haitian Creole',
  'Hausa', 'Irish', 'Javanese', 'Kannada', 'Kazakh', 'Khmer', 'Kurdish',
  'Kyrgyz', 'Lao', 'Latvian', 'Lithuanian', 'Luxembourgish', 'Macedonian',
  'Malagasy', 'Maltese', 'Mongolian', 'Nepali', 'Pashto', 'Samoan', 'Shona',
  'Sinhala', 'Slovak', 'Slovenian', 'Somali', 'Sundanese', 'Tajik', 'Tatar',
  'Tigrinya', 'Tongan', 'Turkmen', 'Ukrainian', 'Uzbek', 'Wolof', 'Xhosa',
  'Yoruba', 'Zulu', 'Basque', 'Galician', 'Welsh', 'Scots Gaelic', 'Maori',
  'Inuktitut', 'Quechua', 'Aymara', 'Tswana', 'Faroese', 'Guarani', 'Kirundi',
];

export default languages;
